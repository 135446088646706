let url, clientId;
switch (process.env.REACT_APP_ENV) {
  case "local":
    url = "http://localhost:8080";
    clientId =
      "342622134777-2c5o5ddlc3ugql35lu5798odjmirqnsq.apps.googleusercontent.com";
    break;
  case "stage":
    url = "http://localhost:8080";
    clientId =
      "342622134777-lood9ob6vsatsruoquf7cumm4qo78ori.apps.googleusercontent.com";
    break;
  case "prod":
    url = "https://kc.retailpulse.ai";
    clientId =
      "342622134777-lood9ob6vsatsruoquf7cumm4qo78ori.apps.googleusercontent.com";
  default:
    url = "https://kc.retailpulse.ai";
    clientId =
      "342622134777-lood9ob6vsatsruoquf7cumm4qo78ori.apps.googleusercontent.com";

    break;
}

export { url, clientId };
