import {createContext, useState} from "react"

const TimeRange = createContext(7)

const TimeContext = ({ children }) => {
    const [time, setTime] = useState(3)
    const setRange = (time) => {
        setTime(time)
    }
    return <TimeRange.Provider value={ {time, setRange} } >{children}</TimeRange.Provider>
}
export {
    TimeRange,
    TimeContext
}