// ** React Imports
import { Suspense, lazy } from "react";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom";

// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";

// ** Intl, CASL & ThemeColors Context
import ability from "./configs/acl/ability";
import { ToastContainer } from "react-toastify";
import { AbilityContext } from "./utility/context/Can";
import { TimeContext } from "./utility/context/timeRange";
import { ThemeContext } from "./utility/context/ThemeColors";
import { IntlProviderWrapper } from "./utility/context/Internationalization";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** Fake Database
import "./@fake-db";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";

// ** Service Worker
import * as serviceWorker from "./serviceWorker";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./custom-utils/apolloClient";
const queryClient = new QueryClient();

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

Sentry.init({
  dsn: "https://a893b012b8ddc71decc200c443ca67ad@o4507447460036608.ingest.us.sentry.io/4507451243692032",
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.5, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
});

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Suspense fallback={<Spinner />}>
          <AbilityContext.Provider value={ability}>
            <ThemeContext>
              <IntlProviderWrapper>
                <TimeContext>
                  <LazyApp />
                </TimeContext>
                <ToastContainer newestOnTop />
              </IntlProviderWrapper>
            </ThemeContext>
          </AbilityContext.Provider>
        </Suspense>
      </Provider>
    </QueryClientProvider>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
