import { ApolloClient, InMemoryCache, useQuery, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import moment from 'moment'
import {getUserData} from "../auth/utils"
import { url } from '../configs/config'

const cache = new InMemoryCache()

const data  = getUserData()
const token = (data !== null) ? data.accessToken : ""
const headers =  {
        Authorization: `${token}`

}

const httpLink = createHttpLink({
    uri: `${url}/query`
})

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const data  = getUserData()
    const token = (data !== null) ? data.accessToken : ""    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `${token}` : ""
        }
    }
})

const apolloClient = new ApolloClient({
    // Provide required constructor fields
    cache,
    link: authLink.concat(httpLink),

    // Provide some optional constructor fields
    name: 'react-web-client',
    version: '1.3',
    queryDeduplication: true,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-first'
        }
    },
    query: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all'
    }
})


export {
    apolloClient
}